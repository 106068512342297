import { useState, useEffect } from 'react';
import './App.css';
import Logo from './images/logo.svg';
import GalleryIcon from './images/picture.png';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function App() {

  const [ loader, setLoader ] = useState(false);

  useEffect(() => {
    axios.get('https://control-panel.tanfeez.net/api/v2/categories').then(res => {
      if(res.data.success) {
        setCats(res.data.data)
      }
    })
  },[])

  const [ cats, setCats ] = useState([]);

  const [ subCats, setSubCats ] = useState([]);

  const [ selectedCat, setSelectedCat ] = useState('');

  const [modal, setModal] = useState(false);

  const toggleModel = () => setModal(!modal);

  const [subModal, setSubModal] = useState(false);

  const toggleSubModel = () => setSubModal(!subModal);

  useEffect(() => {
    if(selectedCat) {
      axios.get(`https://control-panel.tanfeez.net/api/v2/categories/${selectedCat}`).then(res => {
        setSubCats(res.data.data.sub_category)
      })
    }
   
  },[selectedCat])

  const [ images, setImages ] = useState([]);

  const CompanyImages = (e) => {

      const text = e.target.files

      for(let i = 0; i < text.length; i++) {
          setImages((old) => [...old, URL.createObjectURL(text[i])]);
          setState((old) => ({...old, images : [...text]}));
      }
  }

  const [ files, setFiles ] = useState([]);

  const FilesImages = (e) => {

      const text = e.target.files

      for(let i = 0; i < text.length; i++) {
        setFiles((old) => [...old, URL.createObjectURL(text[i])]);
        setState((old) => ({...old, files : [...text]}));
      }
  }

  const [ state, setState ] = useState({
    full_name : "",
    phone : "",
    images : [],
    files : [],
    parent_category_id : "",
    subcategories_ids : []
  })

  const [ errors, setErrors ] = useState({
    full_name : "",
    phone : "",
    images : "",
    files : "",
    parent_category_id : "",
    subcategories_ids : ""
  })

  const HandelValidation = () => {

    let Valid = true;

    if(state.full_name === "") {
      setErrors((old) => ({...old, full_name : "الاسم بالكامل مطلوب"}));
      Valid = false;
    }

    if(state.phone === "") {

      setErrors((old) => ({...old, phone : "رقم الهاتف مطلوب"}));
      Valid = false;
    }

    // if(state.images.length === 0) {
    //   setErrors((old) => ({...old, images : "صور الشركه مطلوبة"}));
    //   Valid = false;
    // }

    // if(state.files.length === 0) {
    //   setErrors((old) => ({...old, files : "صور المستندات مطلوبة"}));
    //   Valid = false;
    // }

    if(state.parent_category_id === "") {

      setErrors((old) => ({...old, parent_category_id : "القسم الرئيسي مطلوب"}));
      Valid = false;
    }

    if(state.subcategories_ids.length === 0) {
      setErrors((old) => ({...old, subcategories_ids : "الاقسام الفرعية مطلوبة"}));
      Valid = false;
    }

    return Valid;
  }

  const HandelSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

      formData.append('full_name', state.full_name);
      formData.append('phone', state.phone);
      for(let i =0; i < state.images.length; i++) {
          formData.append(`images[${i}]`, state.images[i])
      }
      for(let i =0; i < state.files.length; i++) {
          formData.append(`files[${i}]`, state.files[i])
      }
      formData.append('parent_category_id', state.parent_category_id);
      for(let i =0; i < state.subcategories_ids.length; i++) {
        formData.append(`subcategories_ids[${i}]`, state.subcategories_ids[i])
      }

    if(HandelValidation()) {
      axios.post('https://control-panel.tanfeez.net/api/v2/join_request', formData).then(res => {
        if(res.data.success) {
          window.location.reload();
        }
        // console.log(res.data.success)
      })
      setLoader(true);
    }
  }

  return (
    <div className="app">
      {
        loader && <div className='load'>
                    <span className="loader"></span>
                  </div>
      }
      <div className='logo'>
        <img src={Logo} alt="Logo" className='img-fluid' />
      </div>
      <form className='landing-form' onSubmit={HandelSubmit}>
        <div className='form-group'>
          <label htmlFor='FullName'>الاسم بالكامل</label>
          <input type="text" className='form-control' id="FullName" placeholder='أدخل اسمك بالكامل' onChange={
            (e) => {
              setState((old) => ({...old, full_name : e.target.value}));
              setErrors((old) => ({...old, full_name : ""}));
            }
          }/>
          {
              errors.full_name && <small className="form-text text-danger">{errors.full_name}</small>
          }
        </div>
        <div className='form-group'>
          <label htmlFor='Phone'>رقم الهاتف</label>
          <input type="tel" className='form-control' id="Phone" placeholder='ادخل رقم الهاتف' onChange={
            (e) => {
              setState((old) => ({...old, phone : e.target.value}));
              setErrors((old) => ({...old, phone : ""}));
            }
          }/>
          {
              errors.phone && <small className="form-text text-danger">{errors.phone}</small>
          }
        </div>
        <div className='form-group'>
          <label htmlFor='Cat'>تخصص الشركة الاساسي</label>
          <div className='cat-box' onClick={() => {toggleModel(); } }>
            <span>اضغط هنا لتحديد القسم الرئيسي</span>
          </div>
          {
              errors.parent_category_id && <small className="form-text text-danger">{errors.parent_category_id}</small>
          }
        </div>
        <div className='form-group'>
          <label htmlFor='SubCat'>تخصص الشركة الفرعي</label>
          <div className='cat-box' onClick={() => {toggleSubModel(); }}>
          اضغط هنا لتحديد الأقسام الفرعية
          </div>
          {
              errors.subcategories_ids && <small className="form-text text-danger">{errors.subcategories_ids}</small>
          }
        </div>
        {/* <div className='form-group'>
          <label htmlFor='CompanyGallery'>صور الشركة</label>
          <input type="file" accept="image/*" className='form-control d-none' id="CompanyGallery" multiple onChange={CompanyImages} />
          <label htmlFor='CompanyGallery' className='upload-img'>
            {
              images.length === 0 ?
              <div>
                <img src={GalleryIcon} alt="Gallery" />
                <div className='img-text'>لم يتم اختيار صور بعد</div>
              </div> :
              <div className='images'>
                {
                  images.map((item, index) => (
                    <div className='image' key={index}>
                      <img src={item} alt="text" />
                    </div>
                  ))
                }
            </div>
            }
          </label>
          {
              errors.images && <small className="form-text text-danger">{errors.images}</small>
          }
        </div> */}
        {/* <div className='form-group'>
          <label htmlFor='Files'>المستندات القانونية (السجل التجاري -  البطاقة الضريبة - بطاقة شخصية)</label>
          <input type="file" accept="image/*" className='form-control d-none' id="Files" multiple onChange={FilesImages}/>
          <label htmlFor='Files' className='upload-img'>
          {
              files.length === 0 ?
              <div>
                <img src={GalleryIcon} alt="Gallery" />
                <div className='img-text'>لم يتم اختيار صور بعد</div>
              </div> :
              <div className='images'>
                {
                  files.map((item, index) => (
                    <div className='image' key={index}>
                      <img src={item} alt="text" />
                    </div>
                  ))
                }
            </div>
            }
          </label>
          {
              errors.files && <small className="form-text text-danger">{errors.files}</small>
          }
        </div> */}
        <button type='submit' className='send'>إرسال</button>
      </form>
      <Modal isOpen={modal} toggle={toggleModel}>
            <ModalHeader toggle={toggleModel}></ModalHeader>
            <ModalBody>
              <h3 className='pop-title'>اختر التخصص الاساسي</h3>  
                <div className='cats-list'>
                  {
                    cats.map(item => (
                      <div className={`cat-list-box ${item.slug === selectedCat ? "active" : ""}`} key={item.id} onClick={
                        () => {
                          setSelectedCat(item.slug);
                          setModal(false);
                          setState((old) => ({...old, parent_category_id : item.id}));
                        }
                      }>
                        <img src={item.icon} alt={item.name} className="img-fluid" />
                        <div className='cat-name' style={{color : item.color}}>{item.name}</div>
                      </div>
                    ))
                  }
                </div>
            </ModalBody>
        </Modal>
      <Modal isOpen={subModal} toggle={toggleSubModel}>
            <ModalHeader toggle={toggleSubModel}></ModalHeader>
            <ModalBody>
              <h3 className='pop-title'>اختر الأقسام الفرعية</h3>
              {
                subCats.length === 0 ? <div>يجب عليك اختيار القسم الاساسي اولا</div> :
                <div className='sub-cats-list'>
                  {
                    subCats?.map(item => (
                      <div className={`sub-cat-list-box ${state.subcategories_ids.includes(item.id)  ? "active" : ""}`} key={item.id} onClick={
                        () => {

                          if(!state.subcategories_ids.includes(item.id)) {
                            setState((old) => ({...old, subcategories_ids : [...state.subcategories_ids, item.id]}))
                          }else {
                            state.subcategories_ids.splice(state.subcategories_ids.indexOf(item.id), 1)
                            setState((old) => ({...old, subcategories_ids : [...state.subcategories_ids]}))
                          }
                        }
                      }>
                        <div className='cat-name' style={{color : item.color}}>{item.name}</div>
                      </div>
                    ))
                  }
                  <div className='save' onClick={
                    () => {
                      setSubModal(false);
                    }
                  }>حفظ</div>
                </div>
              } 
                
            </ModalBody>
      </Modal>
    </div>
  );
}

export default App;
